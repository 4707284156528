import '../../Gallery/gallery.css'
import '../../../Components/HomeGalleryy/homeGalleryy.css'

import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { setPagesSession } from '../../../Components/general-functions/helpers';
import { addImages, deleteImage, getImages } from '../../../services/images';
import { API_BASE_URL } from '../../../constants';



export default function ViewImagesDashboard() {
    const navigate = useNavigate();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const screen460 = window.visualViewport.width <= 460;
    
    const imageStyle = (screen460) ?{
        margin: 5,
        display: 'flex',
        cursor: 'pointer',
        width: 70,
        height: 100,
        borderRadius: 5,
    } :{
        margin: 5,
        display: 'flex',
        cursor: 'pointer',
        width: 200,
        height: 130,
        borderRadius: 5,
        justifyContent: "space-around"
        
    };


    const [category, setCategory] = useState(""); 
    

    const [images, setImages] = useState([]);
    const [newImageFiles, setNewImageFiles] = useState([]);

    useEffect(()=>{

        if(category){
            console.log("loading images...");
            getImages(category).then(response=>{
               var imgs = [];
                response.data.data.forEach(img=>{
                    imgs.push({ src: API_BASE_URL+'/images/'+ category +'/'+img.path, style: imageStyle, id:img.id});
                })
                setImages(imgs);
            }).catch(err=>{
                console.error(err);
                alert("Couldn't load images");
            });
            
        }
        
    },[category])

    useEffect(()=>{
        window.scrollTo(0,0);
          setPagesSession("galleryimages");
          var pages = window.sessionStorage.getItem("pages");
          if(Boolean(pages)){
            pages = JSON.parse(pages);
            if(Boolean(pages.galleryimages) && Boolean(pages.galleryimages.category)){
                setCategory(pages.galleryimages.category);
            }
            else{
                navigate('/dashboard/gallery');
            }
            // var pkeys = Object.getOwnPropertyNames(pages);
            // pkeys = pkeys.filter(p=> p != "galleryimages")
            // pkeys.forEach(p => {
            //     delete pages[p];
            // });
            // window.sessionStorage.setItem("pages", JSON.stringify(pages));
          }
          else{
            navigate('/dashboard/gallery');

          }
        
  


        console.log("current category: ", category)
        // if(!Boolean(category)){
        //     navigate('/gallery');
        // }

       
    },[])


    function handleDeleteImage(id){
       if(window.confirm("Are you sure you want to delete this image?")){
            deleteImage(id).then(response=>{
                alert(response.data.message);
                var newImages = images.filter(img=> img.id != id);
                setImages(newImages);
            }).catch(err=>{
                alert("Couldn't delete the image");
                console.error(err);
            })
       }
        
    }

    function onAddMore(){
        var data = new FormData();
        data.append('category', category);
        var keys = Object.getOwnPropertyNames(newImageFiles);
        keys.forEach(k=>{
            data.append('file'+k, newImageFiles[k]);
        })

        addImages(data).then(response=>{

            var imagesClone = [...images];
            response.data.data.forEach(img=>{
                imagesClone.push({ src: API_BASE_URL+'/images/'+ category +'/'+img.path, style: imageStyle, id:img.id});
            })
            setImages(imagesClone);

            alert("Images are added Successfully");
        }).catch(err=>{
            console.error(err);
            alert("Couldn't add images");
        })
    }

    const imageRenderer = ({ index, left, top, key, photo }) => (

        <div className="col-sm-1 img-container" style={{width: imageStyle.width, height: imageStyle.height, margin:"1%", padding:"0", position:"relative"}}>
            <img className='image' {...photo} alt="First slide"/>
            
            <div class="delete-button">
                <div onClick={()=>handleDeleteImage(photo.id)} class="delete-text">X</div>
            </div>
        </div>
        
      );

    return (
        <>
        {(Boolean(category))?
            <div className='container mb-5'>
                <div className="row">
                    <div className="col text-center">
                        <h1 className='galleryy-title '><span className='head-design'>{category}</span> </h1>

                        <Gallery photos={images} renderImage={imageRenderer} onClick={openLightbox} />
                        <ModalGateway>
                            {viewerIsOpen ? (
                                <Modal   onClose={closeLightbox}>
                                    <Carousel
                                        currentIndex={currentImage}
                                        views={images.map(x => ({
                                            ...x,
                                            srcset: x.srcSet,
                                            caption: x.title
                                        }))}
                                    />
                                </Modal>
                            ) : null}
                        </ModalGateway>
                    </div>
                </div>

                <div className='row text-center' style={{width:"100%", marginBottom:"3%", marginTop:"1%", marginRight:"0",}}>
                    <div class="form-group">
                        <label for="imageFiles" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Upload Images: </label>
                        <input style={{marginTop:"1%"}} type="file" id="imageFiles" name="imageFiles"  multiple onChange={(e)=>setNewImageFiles(e.target.files)}/>
                        <button style={{marginTop:"1%"}} onClick={onAddMore} type="button" className="btn btn-primary">Add</button>

                    </div>
            

                </div>
                

            </div>
            :
            <></>
        }
        </>
    );
}


// <button type="button" className="btn gallery-btn" onClick={()=>onAddMore()}>{/*className="btn about-home-btn*/}
// Add <span className='title-design'>more</span>
// </button>






