// import './homeActivities.css'
// import React, { useState } from "react";
// import ActivityOne from '../../Components/ActivityOne/activityOne'
// import ActivityTwo from '../../Components/ActivityTwo/activityTwo'
// import ActivityThree from '../../Components/ActivityThree/activityThree'
// import ActivityFour from '../../Components/ActivityFour/activityFour'

// import activityImg from '../../Images/activityImg.jpg';
// import bloodDonationActivity from '../../Images/medical/bloodDonationActivity.jpg';
// import seminarsActivity from '../../Images/career-guidance/seminarsActivity.jpg';
// import medicalCheckUpActivity from '../../Images/medical/medicalCheckUpActivity.jpg';
// import culturalInviteActivity from '../../Images/art/culturalInviteActivity.jpg';


// function HomeActivities() {

//     const [activityOne, setactivityOne] = useState(true)
//     const [activityTwo, setactivityTwo] = useState(false)
//     const [activityThree, setactivityThree] = useState(false)
//     const [activityFour, setactivityFour] = useState(false)

    

//     function setAllFalse() {
//         setactivityOne(false)
//         setactivityTwo(false)
//         setactivityThree(false)
//         setactivityFour(false)
//     }

//     function changeState(function1, state1) {
//         setAllFalse()
//         function1(state1)
//     }

//     // return (

//     //     <div className='container-fluid'> {/*we should wrap the rows with a container, otherwise we will face horizontal empty spaces */}
//     //             <div className="row activity-contents  mb-5">
//     //                 <h5 className='activity-how-we-work'>How we work</h5>
//     //                 <h1 className='activity-text'>Abu Dhabi malayalee samajam activities</h1>
//     //                 <div className="col-lg-4 col-md-4 col-sm-12 activity-left-section d-flex flex-row" >
//     //                     <div className="d-flex activity-mob">
//     //                         <span className={activityOne === false ? "activity-options-items" : "selected activity-options-items  "} onClick={() => {
//     //                             changeState(setactivityOne, true)
//     //                         }}>
//     //                             1. {" "}
//     //                         </span>
//     //                         <span className={activityTwo === false ? "activity-options-items" : "selected activity-options-items  "} onClick={() => {
//     //                             changeState(setactivityTwo, true)
//     //                         }}>2.{" "}</span>
//     //                         <span className={activityThree === false ? "activity-options-items" : "selected activity-options-items  "} onClick={() => {
//     //                             changeState(setactivityThree, true)
//     //                         }}>3.{" "}
//     //                         </span>

//     //                         <span className={activityFour === false ? "activity-options-items" : "selected activity-options-items  "} onClick={() => {
//     //                             changeState(setactivityFour, true)
//     //                         }}>
//     //                             4.
//     //                         </span>

//     //                     </div>


//     //                 </div>
//     //                 <div className="col-lg-8 col-md-8 activity-right-section">
//     //                     {activityOne === true && <ActivityOne />}
//     //                     {activityTwo === true && <ActivityTwo />}
//     //                     {activityThree === true && <ActivityThree />}
//     //                     {activityFour === true && <ActivityFour />}
//     //                 </div>


//     //             </div>
           
//     //     </div>
//     // )


//     const screen1000 = window.visualViewport.width <= 1000;
//     const screen767 = window.visualViewport.width <= 767;

//     const activity1Img = bloodDonationActivity;
//     const activity2Img = medicalCheckUpActivity;
//     const activity3Img = seminarsActivity;
//     const activity4Img = culturalInviteActivity;

//     const [currentImg, setCurrentImg] = useState(activity1Img);



//     return (
//         (screen1000)?
//             <div className='container-fluid' style={{justifyContent: "center", marginBottom:"5%"}}>
//                     <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
//                         <div className='col' style={{width:"100%", textAlign:"center", marginTop:"3%"}}>
//                             <h5 className='activity-how-we-work'>How we work</h5>
//                         </div>
//                     </div>
                    
//                     <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
//                         <div className='col' style={{width:"100%", textAlign:"center"}}>
//                             <h1 className='activity-text'>Abu Dhabi malayalee samajam activities</h1>
//                         </div>
//                     </div>

//                     <div className="row" style={{width:"100%", margin:"0", padding:"0", textAlign:"center", justifyContent:"center"}}>
//                         <div className="col-lg-4 col-md-4 col-sm-12 activity-left-section d-flex flex-row"  > {/*className="col-sm-1 text-center" style={{width:"22%",padding:"0", marginRight:"3%"}} */}
//                             <div className="d-flex activity-mob">
//                                 <span className={activityOne === false ? "activity-options-items" : "selected activity-options-items  "} onClick={() => {
//                                     changeState(setactivityOne, true);
//                                     setCurrentImg(activity1Img);
//                                 }}>
//                                     1. {" "}
//                                 </span>
//                                 <span className={activityTwo === false ? "activity-options-items" : "selected activity-options-items  "} onClick={() => {
//                                     changeState(setactivityTwo, true);
//                                     setCurrentImg(activity2Img);
//                                 }}>2.{" "}</span>
//                                 <span className={activityThree === false ? "activity-options-items" : "selected activity-options-items  "} onClick={() => {
//                                     changeState(setactivityThree, true);
//                                     setCurrentImg(activity3Img);
//                                 }}>3.{" "}
//                                 </span>

//                                 <span className={activityFour === false ? "activity-options-items" : "selected activity-options-items  "} onClick={() => {
//                                     changeState(setactivityFour, true);
//                                     setCurrentImg(activity4Img);
//                                 }}>
//                                     4.
//                                 </span>

//                             </div>
 

//                         </div>
//                         <div className="col-lg-8 col-md-8 activity-right-section"> {/* */}
//                             <div style={{marginTop:"5%"}}>
//                             {activityOne === true && <ActivityOne />}
//                             {activityTwo === true && <ActivityTwo />}
//                             {activityThree === true && <ActivityThree />}
//                             {activityFour === true && <ActivityFour />}
//                             </div>
//                         </div>

//                         <div className="col-lg-8 col-md-8"> {/* */}
//                                 <img src={currentImg} style={{width:"90%", height:"80%", marginTop:"3%", borderRadius:"3%"}}></img>
//                         </div>


//                     </div>
            
//             </div>
//         :

//         <div className='container-fluid' style={{justifyContent: "center", marginBottom:"5%"}}>
//         <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
//             <div className='col' style={{width:"100%", textAlign:"center", marginTop:"3%"}}>
//                 <h5 className='activity-how-we-work'>How we work</h5>
//             </div>
//         </div>
        
//         <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
//             <div className='col' style={{width:"100%", textAlign:"center"}}>
//                 <h1 className='activity-text'>Abu Dhabi malayalee samajam activities</h1>
//             </div>
//         </div>

//         <div className="row" style={{width:"100%", margin:"0", padding:"0", textAlign:"center", justifyContent:"center"}}>
//             <div className="col-sm-1 activity-left-section d-flex flex-row" style={{width:"10%"}} > {/*className="col-sm-1 text-center" style={{width:"22%",padding:"0", marginRight:"3%"}} */}
//                 <div className="d-flex activity-mob">
//                     <span className={activityOne === false ? "activity-options-items" : "selected activity-options-items  "} onClick={() => {
//                         changeState(setactivityOne, true);
//                         setCurrentImg(activity1Img);
//                     }}>
//                         1. {" "}
//                     </span>
//                     <span className={activityTwo === false ? "activity-options-items" : "selected activity-options-items  "} onClick={() => {
//                         changeState(setactivityTwo, true);
//                         setCurrentImg(activity2Img);
//                     }}>2.{" "}</span>
//                     <span className={activityThree === false ? "activity-options-items" : "selected activity-options-items  "} onClick={() => {
//                         changeState(setactivityThree, true);
//                         setCurrentImg(activity3Img);
//                     }}>3.{" "}
//                     </span>

//                     <span className={activityFour === false ? "activity-options-items" : "selected activity-options-items  "} onClick={() => {
//                         changeState(setactivityFour, true);
//                         setCurrentImg(activity4Img);
//                     }}>
//                         4.
//                     </span>

//                 </div>


//             </div>

//             <div className="col-sm-1  activity-right-section" style={{width:"30%"}}> {/* */}
//                 {activityOne === true && <> 
//                     <h1 className="activity-head">Blood donation camps</h1>
//                     <p className="activity-discription">
//                         Donate blood and save millions of lives,
//                         blood scarcity is frequently encountered in health care systems due to lack of motivation among
//                         potential donors, So Abu Dhabi Malayali Samajam has taken a strong initiative in attributing  the
//                         supply of blood by creating awareness campaigns & blood donation campaigns.<br></br><br></br>

//                         We always convey the benefits of blood donation through our campaigns & encourage to donate blood by understanding the benefits that provide to individuals & society. “One blood donation can save up to three lives" AbuDhabi Malayalee Samajam values the life of people and stands for the welfare of society as Blood donors & provide a vital service to the community.
//                     </p></>
//                 }

//                 {activityTwo === true &&
//                     <>
//                         <h1 className="activity-head">Medical Check ups</h1>
//                         <p className="activity-discription">
//                         Offers a wide range of encompassing health check-ups that can identify any early signs of health issues. We aim to provide health assistance to society that helps to determine the current status of health and to predict and understand the future risks. Abu Dhabi Malayalee Samajam cares about the health status of the community and has taken the appropriate measures like medical checkups for society by considering the changing lifestyle of people in today’s world.<br></br><br></br>
// With all of our activities & services, we exhibit our commitment to the community. Along with these efforts, Abu Dhabi Malayalee Samajam also emphasizes the value of leading a healthy lifestyle to prevent health issues and raise public awareness regarding unhealthy lifestyles.

//                         </p>
//                     </>
//                  }
//                 {activityThree === true && 
//                 <>
//                     <h1 className="activity-head">	Seminars </h1>
//                     <p className="activity-discription">
//                     Abu Dhabi Malayalee Samajam conducts seminars on different topics for the awareness of society’s various aspects of the modern world. The seminars cover a range of themes by expert professionals from different sectors and professional practices. We always choose the perfect subjects which have utmost influence & importance in the current environment.<br></br><br></br>

//                     The prime motive for initiating seminars is to educate people on various current scenarios by providing the best information and accurate knowledge. We are proud to announce that we have successfully conducted seminars that benefit everyone.

//                     </p>
//                 </>
                
//                 }

//                 {activityFour === true &&
//                     <>
//                         <h1 className="activity-head">Cultural initiatives</h1>
//                         <p className="activity-discription">
//                             We organize astounding cultural activities and attractions which help everyone to explore. Abu Dhabi Malayalee Samajam has taken initiative in conducting many cultural programs which entertain & attract people and encourages all to be a part of such activities.<br></br><br></br>
//                             Our cultural activities are a great influencer for the upcoming generations. We appreciate all cultures and foster a welcoming atmosphere. Our cultural activities and programs encourage and influence society to be part of such activities & identify & understand the importance of such things in life. These activities provide enjoyment & reduce stress.

//                         </p>
//                     </>
//                 }
//             </div>

//             <div className="col-sm-1" style={{width:"40%"}}> {/* */}
//                     <img src={currentImg} style={{width:"90%", height:"80%", marginTop:"3%", borderRadius:"3%"}}></img>
//             </div>
            

//         </div>
   
// </div>
//     )

// }

// export default HomeActivities;





























import './homeActivities.css'
import React, { useState, useEffect } from "react";
import { getActivities } from '../../services/activities'; 
import { API_BASE_URL, IMAGES_URL } from '../../constants'; 


export default function ActivitiesDashboard() {

    const [activities, setActivities] = useState([]);
    const [activeActivity, setActiveActivity] = useState(0);

  
    useEffect(()=>{
        getActivities().then(response=>{
            setActivities(response.data.data);
            setCurrentImg(response.data.data[0].image.path)
        }).catch(err=>{
            console.log(err);
            alert("Couldn't load activities.");
        })
    },[])
    


    const screen1000 = window.visualViewport.width <= 1000;
    const [currentImg, setCurrentImg] = useState('');
    
    return (
        (screen1000)?
            <div className='container-fluid' style={{justifyContent: "center", marginBottom:"5%"}}>
                    <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
                        <div className='col' style={{width:"100%", textAlign:"center", marginTop:"3%"}}>
                            <h5 className='activity-how-we-work'>How we work</h5>
                        </div>
                    </div>
                    
                    <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
                        <div className='col' style={{width:"100%", textAlign:"center"}}>
                            <h1 className='activity-text'>Abu Dhabi malayalee samajam activities</h1>
                        </div>
                    </div>

                    <div className="row" style={{width:"100%", margin:"0", padding:"0", textAlign:"center", justifyContent:"center"}}>
                        
                        <div className="col-lg-4 col-md-4 col-sm-12 activity-left-section d-flex flex-row"  > {/*className="col-sm-1 text-center" style={{width:"22%",padding:"0", marginRight:"3%"}} */}
                            <div className="d-flex activity-mob">
                                {
                                    activities.map((activity,index)=>{
                                        return(
                                            <>
                                          
                                            <div key={index} className='row' style={{marginBottom:"5%"}}>
                                                <div className="row">
                                                    <div className='col text-center' style={{width:"50%"}}>
                                                        <p className={(activeActivity !== index)? "activity-options-items" : "selected activity-options-items  "} onClick={() => {
                                                            setActiveActivity(index);
                                                            setCurrentImg(activity.image.path);
                                                            }}>
                                                            {(index +1)+". "}
                                                        </p>
                                                    </div>
                                                </div>
                                                                                              
                                            </div>
                                            </>
                                        )
                                    })
                                }   
                             
                            </div>
 

                        </div>
                        <div className="col-lg-8 col-md-8 activity-right-section"> {/* */}
                            <div style={{marginTop:"5%"}}>
                                <div className="">
                                    <div className="activity-section">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col"> {/*className="col-lg-5" */}
                                                    <h1 className="activity-head">{(activities[activeActivity]!== undefined)?activities[activeActivity].title:""}</h1>
                                                    <p className="activity-discription">
                                                        {(activities[activeActivity]!== undefined)?activities[activeActivity].text:""}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-8"> {/* */}
                                <img src={IMAGES_URL+'/'+ currentImg} style={{width:"90%", height:"80%", marginTop:"3%", borderRadius:"3%"}}></img>
                        </div>


                    </div>


                    
            
            </div>
        
        
        :




        <div className='container-fluid' style={{justifyContent: "center", marginBottom:"5%"}}>
        <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
            <div className='col' style={{width:"100%", textAlign:"center", marginTop:"3%"}}>
                <h5 className='activity-how-we-work'>How we work</h5>
            </div>
        </div>
        
        <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
            <div className='col' style={{width:"100%", textAlign:"center"}}>
                <h1 className='activity-text'>Abu Dhabi malayalee samajam activities</h1>
            </div>
        </div>

        <div className="row" style={{width:"100%", margin:"0", padding:"0", textAlign:"center", justifyContent:"center"}}>
           
            <div className="col-sm-1 activity-left-section d-flex flex-row" style={{width:"10%"}} > {/*className="col-sm-1 text-center" style={{width:"22%",padding:"0", marginRight:"3%"}} */}
                <div className="d-flex activity-mob">
                    {
                        activities.map((activity,index)=>{
                            return(
                                <div key={index} className='row' style={{marginBottom:"3%", width:"100%", padding:"0"}}>
                                    <div className='col my-auto' style={{width:"100%", padding:"0", margin:"0"}}>
                                        <p className={(activeActivity !== index)?  "activity-options-items" : "selected activity-options-items  "} onClick={() => {
                                            setActiveActivity(index);
                                            setCurrentImg(activity.image.path);
                                            }}>
                                            {(index +1)+". "}
                                        </p>
                                    </div>
                                </div>
                               
                            )
                        })
                    }   

                </div>


            </div>

            <div className="col-sm-1  activity-right-section" style={{width:"30%"}}> {/* */}
                <h1 className="activity-head">{(activities[activeActivity]!== undefined)? activities[activeActivity].title : ""}</h1>
                <p className="activity-discription">
                {(activities[activeActivity]!== undefined)? activities[activeActivity].text : ""}
                </p>
            </div>

            <div className="col-sm-1" style={{width:"40%"}}> {/* */}
                    <img src={IMAGES_URL+'/'+currentImg} style={{width:"90%", height:"80%", marginTop:"3%", borderRadius:"3%"}}></img>
            </div>
            

        </div>

        
   
</div>
    )

}










