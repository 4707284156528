import { useEffect, useState } from 'react'
import EventDummy from '../../Components/EventDummy/eventDummy'
import { setPagesSession } from '../../Components/general-functions/helpers'
import HomeEvent from '../../Components/HomeEvent/homeEvent'
import './event.css'
import Logo from '../../Images/Logo.jpg'
import '../loadinglogo.css';
export default function Event() {
    useEffect(()=>{
        setPagesSession("events");//this will remove all other pages values from the key "pages" (which is a stringified json object) of our session storage.
    },[])

    const [loading, setLoading] = useState(true);
    const [readyHomeEvent, setReadyHomeEvent] = useState(0);
    const [readyEventDummy, setReadyEventDummy] = useState(0);


    useEffect(()=>{
        if(readyEventDummy && readyHomeEvent){
            console.log("everything is ready");
            setLoading(false);
        }
    },[readyEventDummy, readyHomeEvent])

    return (
        <>
        
        <div hidden={!loading} className="container-fluid" style={{width:"100vw", height:"100vh",}}>
          <div className="row text-center">
              <div className="col my-auto text-center" style={{width:"100%", height:"100%", }}>
                  

                
                  <img

                      src={Logo}
                    //   style={{width:"200px", height:"200px", borderRadius:"50%" , marginTop:"25vh"}} 
                    className="loading-logo"
                  />
              </div>
          </div>
        </div>
        
        <div hidden={loading}  className='event-details mb-0' style={{margin:"0", padding:"0"}}>
            <EventDummy readyEventDummy={readyEventDummy} setReadyEventDummy= {setReadyEventDummy}/> 
            <HomeEvent  readyHomeEvent={readyHomeEvent} setReadyHomeEvent= {setReadyHomeEvent}/>
        </div>
        </>
    )
}


//readyComponents={readyComponents} setReadyComponents= {setReadyComponents}