// // import './homeEvent.css'
// // import eventBgImg from '../../Images/Deebam.png'
// // import summerCampEvent from '../../Images/summerCampEvent.jpeg'
// // // import Perunnaaalkissa from '../../Images/Perunnaaalkissa.jpg'

// // import Eidevent from '../../Images/Eidevent.jpg'
// // import Summercampp from '../../Images/Summercampp.jpg'
// // import Eventindependence from '../../Images/Eventindependence.jpg'
// // import Eventonam from '../../Images/Eventonam.jpg'
// // import Eventfilm from '../../Images/Filmfestadms.jpg'
// // import EventZero from '../EventZero/eventZero'
// // import EventOne from "../EventOne/eventOne";
// // import EventTwo from "../EventTwo/eventTwo";
// // import EventThree from "../EventThree/eventThree";
// // import EventFour from "../EventFour/eventFour"
// // import EventFive from "../EventFive/eventFive"
// // import { Parallax } from "react-parallax";
// // import React, { useState } from "react";



// // export default function HomeEvent() {


// //     const [eventZero,setEventZero] = useState(true)
// //     const [eventOne, setEventOne] = useState(false)
// //     const [eventTwo, setEventTwo] = useState(false)
// //     const [eventThree, setEventThree] = useState(false)
// //     const [eventFour, setEventFour] = useState(false)
// //     const [eventFive, setEventFive] = useState(false)


// //     function setAllFalse() {
// //         setEventZero(false)
// //         setEventOne(false)
// //         setEventTwo(false)
// //         setEventThree(false)
// //         setEventFour(false)
// //         setEventFive(false)

// //     }

// //     function changeState(function1, state1) {
// //         setAllFalse()
// //         function1(state1)
// //     }
// //     return (
// //         <>
        
// //             <div className="event">
// //                     <div className='event-bg-img'>
// //                         <div >
// //                             <div className="event-section ">
// //                                 <div className="container">
// //                                     <h5 className="event-title">Upcoming Events</h5>

// //                                     <div className="row mt-5">

// //                                         <div className=" col-sm-12 col-md-5  col-lg-5 col-12">
// //                                             {eventZero === true && <EventZero />}
// //                                             {eventOne === true && <EventOne />}
// //                                             {eventTwo === true && <EventTwo />}
// //                                             {eventThree === true && <EventThree />}
// //                                             {eventFour === true && <EventFour />}
// //                                             {eventFive === true && <EventFive />}

// //                                         </div>
// //                                         <div className="col-sm-12  col-md-5 col-lg-5 col-12 d-flex flex-row" >

// //                                             <div className="d-flex flex-column event-items text-white">

// //                                             <span className="icons-span" onClick={() => {
// //                                                         changeState(setEventZero, true)
// //                                                     }} >


// //                                                     <img className='event-logo-img' src={summerCampEvent} alt="" />

// //                                                 </span>

// //                                                 <span className="icons-span" onClick={() => {
// //                                                         changeState(setEventOne, true)
// //                                                     }} >


// //                                                     <img className='event-logo-img' src={Eidevent} alt="" />

// //                                                 </span>
// //                                                 <span className="icons-span" onClick={() => {
// //                                                     changeState(setEventTwo, true)
// //                                                 }}>

// //                                                     <img className='event-logo-img' src={Summercampp} alt="" />
// //                                                 </span>

// //                                                 <span className="icons-span" onClick={() => {
// //                                                     changeState(setEventThree, true)
// //                                                 }}>

// //                                                     <img className='event-logo-img' src={Eventindependence} alt="" />
// //                                                 </span>

                                                
// //                                                 <span className="icons-span" onClick={() => {
// //                                                     changeState(setEventFour, true)
// //                                                 }}>

// //                                                     <img className='event-logo-img' src={Eventonam} alt="" />
// //                                                 </span>

// //                                                 <span className="icons-span" onClick={() => {
// //                                                     changeState(setEventFive, true)
// //                                                 }}>

// //                                                     <img className='event-logo-img' src={Eventfilm} alt="" />
// //                                                 </span>





// //                                             </div>
// //                                             <div className="table-content d-flex flex-column">

// //                                             {/* <span className={eventZero === false ? "event-options-items" : "selected-event-options-items  "}
// //                                                     onClick={() => {
// //                                                         changeState(setEventZero, true)
// //                                                     }}>


// //                                                     <p className="table-date">July 10 <strong></strong><br /> <span className='table-description' > Perunnal kissa</span> </p> {" "}
// //                                                 </span> */}
// //                                                 <span className={eventZero === false ? "event-options-items" : "selected-event-options-items  "}
// //                                                     onClick={() => {
// //                                                         changeState(setEventZero, true)
// //                                                     }}>


// //                                                     <p className="table-date">July 29 <strong></strong><br /> <span className='table-description' >Summer Camp</span> </p> {" "}
// //                                                 </span>


// //                                                 <span className={eventOne === false ? "event-options-items" : "selected-event-options-items  "}
// //                                                     onClick={() => {
// //                                                         changeState(setEventOne, true)
// //                                                     }}>
// //                                                     <p className="table-date">July <strong></strong><br /> <span className='table-description' >Eid al-Adha</span> </p> {" "}
// //                                                 </span>
// //                                                 <span className={eventTwo === false ? "event-options-items" : "selected-event-options-items  "} onClick={() => {
// //                                                     changeState(setEventTwo, true)
// //                                                 }}><p className="table-date ">July <strong></strong><br /> <span className='table-description' > Summer camp</span> </p>{" "}</span>

// //                                                 <span className={eventThree === false ? "event-options-items" : "selected-event-options-items  "} onClick={() => {
// //                                                     changeState(setEventThree, true)
// //                                                 }}><p className="table-date ">August <strong></strong> <br /> <span className='table-description' >Independence Day</span> </p>{" "}</span>

                                                
// //                                                   <span className={eventFour === false ? "event-options-items" : "selected-event-options-items  "} onClick={() => {
// //                                                     changeState(setEventFour, true)
// //                                                 }}><p className="table-date ">September <strong></strong><br /> <span className='table-description' >Onam</span> </p>{" "}</span>

// //                                                     <span className={eventFive === false ? "event-options-items" : "selected-event-options-items  "} onClick={() => {
// //                                                     changeState(setEventFive, true)
// //                                                 }}><p className="table-date ">November <strong></strong><br /> <span className='table-description' >Film Fest</span> </p>{" "}</span>
// //                                             </div>


// //                                         </div>
// //                                         <div className="col-sm-12  col-md-2 col-lg-2 "></div>
// //                                     </div>
// //                                 </div>

// //                             </div>
// //                         </div>
// //                     </div>
// //                     {/* <div className="event-section mt-5">
// //                     <div className="container">
// //                         <h5 className="event-title">Our Coming Events</h5>

// //                         <div className="row mt-5">

// //                             <div className=" col-sm-12 col-md-5  col-lg-5 col-12">
// //                                 {eventOne === true && <EventOne />}
// //                                 {eventTwo === true && <EventTwo />}

// //                             </div>
// //                             <div className="col-sm-12  col-md-5 col-lg-5 col-12 d-flex flex-row" >

// //                                 <div className="d-flex flex-column event-items text-white">

// //                                     <span className="icons-span">
// //                                         <img className='event-logo-img' src={eventLogo1} alt="" />
// //                                     </span>
// //                                     <span className="icons-span">
// //                                         <img className='event-logo-img' src={eventLogo2} alt="" />
// //                                     </span>
// //                                 </div>
// //                                 <div className="table-content d-flex flex-column">
// //                                     <span className={eventOne === false ? "event-options-items" : "selected-event-options-items  "}
// //                                         onClick={() => {
// //                                             changeState(setEventOne, true)
// //                                         }}>


// //                                         <p className="table-date">June 21 <strong>.</strong> YOGA session<br /> <span className='table-description' > International Day of YOGA</span> </p> {" "}
// //                                     </span>
// //                                     <span className={eventTwo === false ? "event-options-items" : "selected-event-options-items  "} onClick={() => {
// //                                         changeState(setEventTwo, true)
// //                                     }}><p className="table-date">June 21 <strong>.</strong> YOGA session<br /> <span className='table-description' > International Day of YOGA</span> </p>{" "}</span>
// //                                 </div>


// //                             </div>
// //                             <div className="col-sm-12  col-md-2 col-lg-2 "></div>
// //                         </div>
// //                     </div>

// //                 </div> */}
               

// //             </div>
// //         </>
// //     )
// // }























// import './homeEvent.css';
// import React, { useState } from "react";
// import { API_BASE_URL } from '../../constants';
// import {  getEvents } from '../../services/events'
// import { useNavigate } from "react-router-dom";
// import { useEffect } from 'react';


// export default function EventsDashboard() {

//     const [events, setEvents] = useState([]);
//     const [activeEvent, setActiveEvent] = useState(null);
//     const navigate = useNavigate();

//     useEffect(()=>{
//         getEvents().then(response=>{
//             setEvents(response.data.data);
//             setActiveEvent(0);
//         }).catch(err=>{
//             console.log(err);
//             alert("Couldn't load activities.");
//         })
//     },[])


//     return (
//         <>
        
//             <div className="event" >
//                     <div className='event-bg-img' style={{backgroundSize:""}}> {/* backgroundSize:"cover" */}
//                         <div >
//                             <div className="event-section">
//                                 <div className="container">
                                    

//                                     <div className="row mt-5">
//                                     <h5 style={{marginBottom:"2%", marginTop:"2%"}} className="event-title">Upcoming Events</h5>
//                                         <div className=" col-sm-12 col-md-5  col-lg-5 col-12">
//                                             <div className="work-inside-new mx-2 mt-3">
//                                                 <img className='event-img' src={(events[activeEvent]!== undefined)? API_BASE_URL +'/images/'+ events[activeEvent].image.path: ""} alt={(events[activeEvent])? events[activeEvent].name : "No Events"} />
//                                             </div>
//                                         </div>
//                                         <div className="col-sm-12  col-md-5 col-lg-5 col-12 d-flex flex-row" >

//                                             <div className="d-flex flex-column event-items text-white">
//                                                 {
//                                                     events.map((e, index)=>{
//                                                         return(
//                                                             <span className="icons-span" style={{height:"100%"}} onClick={() => {setActiveEvent(index)}} >
//                                                                 <img className='event-logo-img' src={API_BASE_URL+'/images/'+ e.image.path} alt="" />
//                                                             </span>
//                                                         )
//                                                     })                                                       
//                                                 }         

//                                             </div>

//                                             <div className="table-content d-flex flex-column">
//                                                 {
//                                                     events.map((e,index)=>{
//                                                         return(
//                                                             <div style={{marginBottom:"3%"}}>
//                                                                 <span className={(activeEvent === index) ? "event-options-items" : "selected-event-options-items  "} style={{height:"100%"}} onClick={() => {setActiveEvent(index)}}>
//                                                                     <p className="table-date">{e.date}<strong></strong><br /> <span className='table-description' >{e.title}</span> </p> {" "}
//                                                                 </span>
//                                                             </div>
                                                            
//                                                         )
//                                                     })
//                                                 }
                                               
//                                             </div>

//                                         </div>
//                                         {/* <div className="col-sm-12  col-md-2 col-lg-2 ">
                                           
//                                         </div> */}
                                        
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }






















// import './homeEvent.css'
// import eventBgImg from '../../Images/Deebam.png'
// import summerCampEvent from '../../Images/summerCampEvent.jpeg'
// // import Perunnaaalkissa from '../../Images/Perunnaaalkissa.jpg'

// import Eidevent from '../../Images/Eidevent.jpg'
// import Summercampp from '../../Images/Summercampp.jpg'
// import Eventindependence from '../../Images/Eventindependence.jpg'
// import Eventonam from '../../Images/Eventonam.jpg'
// import Eventfilm from '../../Images/Filmfestadms.jpg'
// import EventZero from '../EventZero/eventZero'
// import EventOne from "../EventOne/eventOne";
// import EventTwo from "../EventTwo/eventTwo";
// import EventThree from "../EventThree/eventThree";
// import EventFour from "../EventFour/eventFour"
// import EventFive from "../EventFive/eventFive"
// import { Parallax } from "react-parallax";
// import React, { useState } from "react";



// export default function HomeEvent() {


//     const [eventZero,setEventZero] = useState(true)
//     const [eventOne, setEventOne] = useState(false)
//     const [eventTwo, setEventTwo] = useState(false)
//     const [eventThree, setEventThree] = useState(false)
//     const [eventFour, setEventFour] = useState(false)
//     const [eventFive, setEventFive] = useState(false)


//     function setAllFalse() {
//         setEventZero(false)
//         setEventOne(false)
//         setEventTwo(false)
//         setEventThree(false)
//         setEventFour(false)
//         setEventFive(false)

//     }

//     function changeState(function1, state1) {
//         setAllFalse()
//         function1(state1)
//     }
//     return (
//         <>
        
//             <div className="event">
//                     <div className='event-bg-img'>
//                         <div >
//                             <div className="event-section ">
//                                 <div className="container">
//                                     <h5 className="event-title">Upcoming Events</h5>

//                                     <div className="row mt-5">

//                                         <div className=" col-sm-12 col-md-5  col-lg-5 col-12">
//                                             {eventZero === true && <EventZero />}
//                                             {eventOne === true && <EventOne />}
//                                             {eventTwo === true && <EventTwo />}
//                                             {eventThree === true && <EventThree />}
//                                             {eventFour === true && <EventFour />}
//                                             {eventFive === true && <EventFive />}

//                                         </div>
//                                         <div className="col-sm-12  col-md-5 col-lg-5 col-12 d-flex flex-row" >

//                                             <div className="d-flex flex-column event-items text-white">

//                                             <span className="icons-span" onClick={() => {
//                                                         changeState(setEventZero, true)
//                                                     }} >


//                                                     <img className='event-logo-img' src={summerCampEvent} alt="" />

//                                                 </span>

//                                                 <span className="icons-span" onClick={() => {
//                                                         changeState(setEventOne, true)
//                                                     }} >


//                                                     <img className='event-logo-img' src={Eidevent} alt="" />

//                                                 </span>
//                                                 <span className="icons-span" onClick={() => {
//                                                     changeState(setEventTwo, true)
//                                                 }}>

//                                                     <img className='event-logo-img' src={Summercampp} alt="" />
//                                                 </span>

//                                                 <span className="icons-span" onClick={() => {
//                                                     changeState(setEventThree, true)
//                                                 }}>

//                                                     <img className='event-logo-img' src={Eventindependence} alt="" />
//                                                 </span>

                                                
//                                                 <span className="icons-span" onClick={() => {
//                                                     changeState(setEventFour, true)
//                                                 }}>

//                                                     <img className='event-logo-img' src={Eventonam} alt="" />
//                                                 </span>

//                                                 <span className="icons-span" onClick={() => {
//                                                     changeState(setEventFive, true)
//                                                 }}>

//                                                     <img className='event-logo-img' src={Eventfilm} alt="" />
//                                                 </span>





//                                             </div>
//                                             <div className="table-content d-flex flex-column">

//                                             {/* <span className={eventZero === false ? "event-options-items" : "selected-event-options-items  "}
//                                                     onClick={() => {
//                                                         changeState(setEventZero, true)
//                                                     }}>


//                                                     <p className="table-date">July 10 <strong></strong><br /> <span className='table-description' > Perunnal kissa</span> </p> {" "}
//                                                 </span> */}
//                                                 <span className={eventZero === false ? "event-options-items" : "selected-event-options-items  "}
//                                                     onClick={() => {
//                                                         changeState(setEventZero, true)
//                                                     }}>


//                                                     <p className="table-date">July 29 <strong></strong><br /> <span className='table-description' >Summer Camp</span> </p> {" "}
//                                                 </span>


//                                                 <span className={eventOne === false ? "event-options-items" : "selected-event-options-items  "}
//                                                     onClick={() => {
//                                                         changeState(setEventOne, true)
//                                                     }}>
//                                                     <p className="table-date">July <strong></strong><br /> <span className='table-description' >Eid al-Adha</span> </p> {" "}
//                                                 </span>
//                                                 <span className={eventTwo === false ? "event-options-items" : "selected-event-options-items  "} onClick={() => {
//                                                     changeState(setEventTwo, true)
//                                                 }}><p className="table-date ">July <strong></strong><br /> <span className='table-description' > Summer camp</span> </p>{" "}</span>

//                                                 <span className={eventThree === false ? "event-options-items" : "selected-event-options-items  "} onClick={() => {
//                                                     changeState(setEventThree, true)
//                                                 }}><p className="table-date ">August <strong></strong> <br /> <span className='table-description' >Independence Day</span> </p>{" "}</span>

                                                
//                                                   <span className={eventFour === false ? "event-options-items" : "selected-event-options-items  "} onClick={() => {
//                                                     changeState(setEventFour, true)
//                                                 }}><p className="table-date ">September <strong></strong><br /> <span className='table-description' >Onam</span> </p>{" "}</span>

//                                                     <span className={eventFive === false ? "event-options-items" : "selected-event-options-items  "} onClick={() => {
//                                                     changeState(setEventFive, true)
//                                                 }}><p className="table-date ">November <strong></strong><br /> <span className='table-description' >Film Fest</span> </p>{" "}</span>
//                                             </div>


//                                         </div>
//                                         <div className="col-sm-12  col-md-2 col-lg-2 "></div>
//                                     </div>
//                                 </div>

//                             </div>
//                         </div>
//                     </div>
//                     {/* <div className="event-section mt-5">
//                     <div className="container">
//                         <h5 className="event-title">Our Coming Events</h5>

//                         <div className="row mt-5">

//                             <div className=" col-sm-12 col-md-5  col-lg-5 col-12">
//                                 {eventOne === true && <EventOne />}
//                                 {eventTwo === true && <EventTwo />}

//                             </div>
//                             <div className="col-sm-12  col-md-5 col-lg-5 col-12 d-flex flex-row" >

//                                 <div className="d-flex flex-column event-items text-white">

//                                     <span className="icons-span">
//                                         <img className='event-logo-img' src={eventLogo1} alt="" />
//                                     </span>
//                                     <span className="icons-span">
//                                         <img className='event-logo-img' src={eventLogo2} alt="" />
//                                     </span>
//                                 </div>
//                                 <div className="table-content d-flex flex-column">
//                                     <span className={eventOne === false ? "event-options-items" : "selected-event-options-items  "}
//                                         onClick={() => {
//                                             changeState(setEventOne, true)
//                                         }}>


//                                         <p className="table-date">June 21 <strong>.</strong> YOGA session<br /> <span className='table-description' > International Day of YOGA</span> </p> {" "}
//                                     </span>
//                                     <span className={eventTwo === false ? "event-options-items" : "selected-event-options-items  "} onClick={() => {
//                                         changeState(setEventTwo, true)
//                                     }}><p className="table-date">June 21 <strong>.</strong> YOGA session<br /> <span className='table-description' > International Day of YOGA</span> </p>{" "}</span>
//                                 </div>


//                             </div>
//                             <div className="col-sm-12  col-md-2 col-lg-2 "></div>
//                         </div>
//                     </div>

//                 </div> */}
               

//             </div>
//         </>
//     )
// }























import './homeEvent.css';
import React, { useState } from "react";
import { API_BASE_URL, IMAGES_URL } from '../../constants';
import {  getEvents } from '../../services/events'
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';


export default function EventsDashboard({setReadyHomeEvent}) {
  
    const screen500 = window.visualViewport.width <= 500;
    const [events, setEvents] = useState([]);
    const [activeEvent, setActiveEvent] = useState(null);
    const navigate = useNavigate();

    // const [readyImgsCount, setReadyImgsCount] = useState([]);
    const [imgsCount, setImgsCount] = useState(0);
    var readyImgsCount = 0;

    useEffect(()=>{
        getEvents().then(response=>{
            
            setEvents(response.data.data);
            setActiveEvent(0);
            if(response.data.data.length > 0){
                setImgsCount(response.data.data.length + 1);// +1 for the main big image, because we reread it from the server also.
            }
            else{
                setReadyHomeEvent(true);
            }
        }).catch(err=>{
            console.log(err);
            alert("Couldn't load events.");
        })

    },[])

    function onImgLoad(){
        readyImgsCount+=1;
        console.log("readyImgsCount: ", readyImgsCount);
        if(readyImgsCount == imgsCount) setReadyHomeEvent(true)
    }

    // useEffect(()=>{
    //     console.log("r imgs count: ", readyImgsCount.length)
    //     if(imgsCount > 0 && readyImgsCount.length == imgsCount){
    //         setReadyComponents( readyComponents + 1);
    //     }
    // },[readyImgsCount])


    return (
        <>

            <div  className="event" >
                    <div className='event-bg-img' style={{backgroundSize:""}}> {/* backgroundSize:"cover" */}
                        <div >
                            <div className="event-section">
                                <div className="container">
                                    
                                    {
                                        (screen500)?
                                        <>
                                        <div className="row">
                                            <div className="col text-center">
                                                <h5 style={{marginBottom:"4%", marginTop:"2%"}} className="event-title">Upcoming Events</h5>
                                            </div>
                                        </div>
                                        <div className="row" style={{marginBottom:"7%"}}>
                                            <div className="col" >
                                                <img  onLoad={onImgLoad} className='event-img' src={(events[activeEvent]!== undefined)? IMAGES_URL +'/'+ events[activeEvent].image.path: ""} alt={(events[activeEvent])? events[activeEvent].name : "No Events"} />
                                            </div>
                                        </div>

                                        <div className="row text-center">
                                            <div className="col text-center">	
                                                {
                                                    events.map((e, index)=>{
                                                        return(
                                                            <div key={index} className="row my-auto text-center">
                                                                <div className="col-sm-1 text-center" style={{width:"auto", marginRight: "3%", marginBottom:"2%"}}>
                                                                    <img  onLoad={onImgLoad} className='event-logo-img' src={IMAGES_URL +'/'+ e.image.path} alt="" />
                                                                </div>
                                                                <div className="col-sm-1" style={{width:"auto", }}>
                                                                    <span className={(activeEvent === index) ? "event-options-items" : "selected-event-options-items  "} style={{height:"100%"}} onClick={() => {setActiveEvent(index)}}>
                                                                    <p className="table-date">
                                                                        {e.start_date}<strong></strong><br /> 
                                                                        {
                                                                            (e.end_date)?
                                                                                <>-{e.end_date}<strong></strong><br /> </>
                                                                            :
                                                                                <></> 
                                                                        }
                                                                        <span className='table-description' >{e.title}</span>  </p> {" "}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        
                                                        )
                                                    })                                                       
                                                }         

                                            </div>

                                                    
                                        </div>
                                    </>
                                    :

                                    <>
                                    <div className="row">
                                        <div className="col text-center">
                                            <h5 style={{marginBottom:"4%", marginTop:"2%"}} className="event-title">Upcoming Events</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-1" style={{width:"40%", marginRight:"3%",}}>
                                            <img onLoad={onImgLoad} style={{height:"400px"}} className='event-img' src={(events[activeEvent]!== undefined)? IMAGES_URL +'/'+ events[activeEvent].image.path: ""} alt={(events[activeEvent])? events[activeEvent].name : "No Events"} />
                                        </div>

                                        <div className="col-sm-1" style={{width:"57%"}}>	
                                            {
                                                events.map((e, index)=>{
                                                    return(
                                                        <div key={index} className="row my-auto">
                                                            <div className="col-sm-1" style={{width:"auto", marginRight: "3%", marginBottom:"2%"}}>
                                                                <img onLoad={onImgLoad} onClick={() => {setActiveEvent(index)}} className='event-logo-img' src={IMAGES_URL +'/'+ e.image.path} alt="" />
                                                            </div>
                                                            <div className="col-sm-1" style={{width:"auto", }}>
                                                                <span className={(activeEvent === index) ? "event-options-items" : "selected-event-options-items  "} style={{height:"100%"}} onClick={() => {setActiveEvent(index)}}>
                                                                <p className="table-date">
                                                                        {e.start_date}<strong></strong><br /> 
                                                                        {
                                                                            (e.end_date)?
                                                                                <>-{e.end_date}<strong></strong><br /> </>
                                                                            :
                                                                                <></> 
                                                                        }
                                                                        <span className='table-description' >{e.title}</span>  </p> {" "}
                                                                </span>
                                                            </div>
                                                        </div>
                                                       
                                                    )
                                                })                                                       
                                            }         

                                        </div>

                                                
                                    </div>
                                    </>
                                    }
                                    
                                </div>
                                
                                        
                                </div>
                            </div>
                        </div>
                    </div>
        </>
    )
}
