import { useState } from "react";
import HomeGalleryy from "../../Components/HomeGalleryy/homeGalleryy";
import Logo from '../../Images/Logo.jpg'
import '../loadinglogo.css';


export default function ImageCategories (){
    const [loading, setLoading] = useState(true);

    return (

        <>
          <div hidden={!loading} className="container-fluid" style={{width:"100vw", height:"100vh",}}>
          <div className="row text-center">
              <div className="col my-auto text-center" style={{width:"100%", height:"100%", }}>
                  

                
                  <img

                      src={Logo}
                    //   style={{width:"200px", height:"200px", borderRadius:"50%" , marginTop:"25vh"}} 
                    className="loading-logo"
                  />
              </div>
          </div>
        </div>

        <div hidden={loading}>
            <HomeGalleryy setLoading = {setLoading}/>
        </div>

        </>
    )
}