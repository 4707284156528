import '../../../Components/HomeEvent/homeEvent.css';
import React, { useState } from "react";
import { API_BASE_URL } from '../../../constants';
import { addEvent, getEvents } from '../../../services/events';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';


export default function EventsDashboard() {

    const [events, setEvents] = useState([]);
    const [activeEvent, setActiveEvent] = useState(null);
    const [newImageFile, setNewImageFile] = useState(null);
    const navigate = useNavigate();

    useEffect(()=>{
        getEvents().then(response=>{
            setEvents(response.data.data);
            setActiveEvent(0);
        }).catch(err=>{
            console.log(err);
            alert("Couldn't load activities.");
        })
    },[])

    function handleAddEvent(){
        var data = new FormData();

       data.append('title', document.getElementById('title').value);
       data.append('date', document.getElementById('date').value);
       data.append('image', newImageFile[0]);

       addEvent(data).then(response=>{
        var eventsClone = [...events];
        eventsClone.push(response.data.data);
        setEvents(eventsClone);
        alert("Event is added successfully");

       }).catch(err=>{
        console.log(err);
        alert("couldn't add the event");
       })

    }

    function onEditClick(id){
        console.log("moving to edit "+id);
        window.sessionStorage.setItem('event', id);
        navigate('/dashboard/events/edit')
    }

    return (
        <>
        
            <div className="event" >
                    <div className='event-bg-img' style={{backgroundSize:""}}>
                        <div >
                            <div className="event-section">
                                <div className="container">
                                    

                                    <div className="row mt-5">
                                    <h5 style={{marginBottom:"2%", marginTop:"2%"}} className="event-title">Upcoming Events</h5>
                                        <div className=" col-sm-12 col-md-5  col-lg-5 col-12">
                                            <div className="work-inside-new mx-2 mt-3">
                                                <img className='event-img' src={(events[activeEvent]!== undefined)? API_BASE_URL +'/images/'+ events[activeEvent].image.path: ""} alt={(events[activeEvent])? events[activeEvent].name : "No Events"} />
                                            </div>
                                        </div>
                                        <div className="col-sm-12  col-md-5 col-lg-5 col-12 d-flex flex-row" >

                                            <div className="d-flex flex-column event-items text-white">
                                                {
                                                    events.map((e, index)=>{
                                                        return(
                                                            <span className="icons-span" style={{height:"100%"}} onClick={() => {setActiveEvent(index)}} >
                                                                <img className='event-logo-img' src={API_BASE_URL+'/images/'+ e.image.path} alt="" />
                                                            </span>
                                                        )
                                                    })                                                       
                                                }         

                                            </div>

                                            <div className="table-content d-flex flex-column">
                                                {
                                                    events.map((e,index)=>{
                                                        return(
                                                            <div style={{marginBottom:"3%"}}>
                                                                <span className={(activeEvent === index) ? "event-options-items" : "selected-event-options-items  "} style={{height:"100%"}} onClick={() => {setActiveEvent(index)}}>
                                                                    <p className="table-date">{e.date}<strong></strong><br /> <span className='table-description' >{e.title}</span> </p> {" "}
                                                                </span>
                                                                <button style={{width:"30%", backgroundColor:"yellow", borderRadius:"50%"}} onClick={()=>onEditClick(e.id)}>Edit</button>
                                                            </div>
                                                            
                                                        )
                                                    })
                                                }
                                               
                                            </div>

                                        </div>
                                        {/* <div className="col-sm-12  col-md-2 col-lg-2 ">
                                           
                                        </div> */}
                                        
                                        
                                        <div className='row text-center' style={{width:"100%", marginTop:"5%", marginRight:"0", marginLeft:"0%", backgroundColor:"#F5F5F5"}}>
                                            <h1 style={{color: "orange", marginTop:"2%"}}>Add New Event</h1>
                                            <div className="row">
                                                <div className="form-group">
                                                    <div className="col">
                                                        <label for="title" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Title: </label>
                                                    </div>
                                                    <div className="col">
                                                        <input style={{marginTop:"1%", width:"30%"}} type="text" id="title" name="title"></input>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="form-group">
                                                    <div className="col">
                                                        <label for="date" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Date: </label>
                                                    </div>
                                                    <div className="col">
                                                        <input type="date" style={{marginTop:"1%",  width:"50%"}} id="date" name="date"></input>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="row">
                                                <div className="form-group">
                                                    <div className="col">
                                                        <label for="imageFile" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Image: </label>
                                                    </div>
                                                    <div className="col">
                                                        <input style={{marginTop:"1%",}} type="file" id="imageFile" name="imageFile"  onChange={(e)=>setNewImageFile(e.target.files)}/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row text-center"style={{marginTop:"1%", marginBottom:"3%", width:"100%"}}>
                                                <div className="col text=center">
                                                    <button style={{marginTop:"1%", width:"25%"}} onClick={handleAddEvent} type="button" className="btn btn-primary">Add Event</button>
                                                </div>
                                            </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
